export const environment = {
  production: false,
  environmentName: 'DEVELOPMENT',
  environmentColor: 'warning',
  apiBaseUrl: 'https://us-central1-crewchief-demo.cloudfunctions.net/cga-dev-api/api',
  tokenKey: 'dev_ga_id_token',
  refreshTokenKey: 'dev_refresh_token',
  selectedAircraftKey: 'dev_sel_aircraft',
  selectedOrgKey: 'dev_sel_org',
  oldestDate: '1900-01-01',
  buildTime: 'TT1585672925640TT',
  firebase: {
    apiKey: 'AIzaSyAYr525VkayZy--7IqYL4kkRd5RqVfh7nQ',
    authDomain: 'crewchief-ga-dev.firebaseapp.com',
    databaseURL: 'https://crewchief-ga-dev.firebaseio.com',
    projectId: 'crewchief-ga-dev',
    storageBucket: 'crewchief-ga-dev.appspot.com',
    messagingSenderId: '510235514590',
    appId: '1:510235514590:web:c8e17f495ffdffb4'
  }
};
